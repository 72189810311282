.gradient-button {
    background: linear-gradient(-45deg, #FFB064, #EAA05B, #DC9756, #C2834C, #B67E48);
    background-size: 400%;
    animation: gradient 4s ease infinite;
}

@media (prefers-reduced-motion) {
    .gradient-button {
        animation: none;
    }
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
